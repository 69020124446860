<template>

  <v-footer color="primary lighten-1" padless app>
    
    <v-bottom-navigation app color="green" height="28">

      <!-- <SignOut class="ml-1" /> -->
      <span class="primary--text ml-2">{{userName}}</span>

      <v-spacer></v-spacer>

      <LocationStatus class="mr-1" />
      <SyncStatus class="mr-1" />
      <NetworkStatus class="mr-1" />
      <Locale class="mr-1 pa-0" />

    </v-bottom-navigation>

  </v-footer>

</template>

<script>
  // import SignOut from '@/components/SignOut';
  import LocationStatus from '@/components/LocationStatus';
  import SyncStatus from '@/components/SyncStatus';
  import NetworkStatus from '@/components/NetworkStatus';
  import Locale from '@/components/Locale';

  export default {

    components: {
      // SignOut,
      LocationStatus,
      SyncStatus,
      NetworkStatus,
      Locale,
    },

    computed: {
      userName() { return this.$store.getters.settings.name; },
    },

  }
</script>
